import axios from 'axios'
import store from '../store'

let getLocationsToken = null
let checkLocationToken = null

export default {
  methods: {
    cancelGetLocations() {
      if (getLocationsToken) {
        getLocationsToken.cancel()
      }
    },

    async searchLocations(
      channelId,
      searchTerm,
      page,
      itemsPerPage
    ) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/locations`,
          {
            params: {
              searchTerm: searchTerm,
              page: page,
              itemsPerPage: itemsPerPage,
              channelId: channelId,
              channelAuthorizeOnly: true
            },
            headers: {
              'Content-type': 'application/json',
              apiToken: process.env.VUE_APP_API_KEY,
              profileToken: store.getters['profileStore/getProfileToken']
            }
          }
        )
        return response
      } catch (e) {
        //console.log(e)
      }
    },

    async getLocations(
      channelId,
      searchTerm,
      page,
      itemsPerPage,
      activationStatus,
      publishStatus,
      countryId
    ) {
      this.cancelGetLocations()
      getLocationsToken = axios.CancelToken.source()

      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/channel/${channelId}/locations`,
        {
          cancelToken: getLocationsToken.token,
          params: {
            q: searchTerm,
            page: page,
            itemsPerPage: itemsPerPage,
            activationStatus: activationStatus,
            publishStatus: publishStatus,
            countryId: countryId
          },
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response

    },

    async getLocationChannels(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/location/${locationId}/channels`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationById(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationState(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/state`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationLanguageIds(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/location/${locationId}/language`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY
          }
        }
      )
      return response
    },

    async getLocationSettings(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/location/${locationId}/settings`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationLicense(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/location/${locationId}/license`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async getLocationExportgroups(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/exportgroups`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async createNewLocation(criteria) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateLocation(location) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${location.Id}`,
        JSON.stringify(location),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationSettings(settings) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/settings`,
        settings,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteLocationChannel(locationId, channelId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/location/${locationId}/channel/${channelId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationChannel(locationId, channelId, packageId = 0) {
      let criteria = {
        LocationId: locationId,
        ChannelId: channelId,
        PackageId: packageId
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/admin/location/channel`,
        criteria,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    saveLocationOwner(locationId, profileId) {
      let criteria = {
        LocationId: locationId,
        ProfileId: profileId
      }

      return axios.post(
        `${process.env.VUE_APP_API_BASE_URL
        }/api/v1/location/${locationId}/owner`,
        criteria,
        {
          headers: {
            'content-type': 'application/json; charset=utf-8',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
    },

    /**
     * Get location logo
     * @param {Number} locationId
     */
    async getLocationLogo(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/logo`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Post new location logo
     * @param {Number} locationId
     * @param {String} imageName
     */
    async saveLocationLogo(locationId, imageName) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/logo`,
        JSON.stringify({
          Id: 0,
          Image: imageName,
          LocationId: locationId,
          IsDefault: true,
          IsLogo: true
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Get location mail settings
     * @param {Number} locationId
     */
    async getLocationMailSettings(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/mail/settings`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationData(location) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${location.Id}`,
        JSON.stringify(location),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationMailSettings(locationId, settings) {
      let criteria = {
        Settings: settings
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/mail/settings`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationMeetingtype(locationId, meetingtype) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/meetingtype`,
        JSON.stringify(meetingtype),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationLanguages(locationId, languages) {
      let criteria = {
        LanguageIds: languages
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/languages`,
        JSON.stringify(criteria),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async saveLocationSocialMediaAccounts(locationId, accounts) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/socialmedia`,
        JSON.stringify(accounts),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Get location images
     * @param {Number} locationId
     */
    async getLocationImages(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/images`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Get location videos
     * @param {Number} locationId
     */
    async getLocationVideos(locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/videos`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Post new location image
     * @param {Number} locationId
     * @param {String} imageName
     */
    async saveLocationImage(locationId, imageName, isDefault = false) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/image`,
        JSON.stringify({
          Image: imageName,
          LocationId: locationId,
          IsDefault: isDefault,
          IsLogo: false
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
     * Save default image
     * @param {Number} locationId
     * @param {Number} imageId
     */
    async saveLocationDefaultImage(locationId, imageId) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/image/default`,
        JSON.stringify({
          ImageId: imageId
        }),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
    * Create location video
    * @param {Number} locationId
    * @param {Object} locationVideo
    */
    async createLocationVideo(locationId, locationVideo) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/video`,
        JSON.stringify(locationVideo),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    /**
    * Update location video
    * @param {Number} locationId
    * @param {Object} locationVideo
    */
    async updateLocationVideo(locationId, locationVideo) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/video`,
        JSON.stringify(locationVideo),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteLocationVideo(videoId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/video/${videoId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async activateLocation(locationId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/activate`,
        JSON.stringify(location),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deactivateLocation(locationId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/deactivate`,
        JSON.stringify(location),
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async deleteLocationImage(imageId) {
      const response = await axios.delete(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/image/${imageId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async checkLocation(channelId, locationId, checkSpaces, checkOptions, checkVouchers) {
      let criteria = {
        ChannelId: channelId,
        LocationId: locationId,
        CheckSpaces: checkSpaces,
        CheckOptions: checkOptions,
        CheckVouchers: checkVouchers
      }

      checkLocationToken = axios.CancelToken.source()

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/check`, criteria,
        {
          cancelToken: checkLocationToken.token,
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async lockLocation(locationId, lockType) {
      let criteria = {
        LocationId: locationId,
        IsLocked: true,
        LockType: lockType,
        ManualOverwrite: false
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/lock`,
        criteria,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async unlockLocation(locationId) {
      let criteria = {
        LocationId: locationId,
        IsLocked: false,
        LockType: 0,
        ManualOverwrite: false
      }

      const response = await axios.post(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/lock`,
        criteria,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async stopLocation(locationId) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/stop`,
        null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },

    async updateActivationStatus(locationId, status) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/location/${locationId}/activation/status`,
        status,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
      return response
    },
  }
}
